import { CookiesActionTypes, CookiesState, SET_ACCEPT_COOKIES } from './types';
import { ACCEPT_COOKIES_NAME } from './actions';

export const initialState: CookiesState = {
  accepted: document.cookie.split(';').some((item) => item.trim().startsWith(ACCEPT_COOKIES_NAME)),
};

function reducer(state = initialState, action: CookiesActionTypes): CookiesState {
  switch (action.type) {
    case SET_ACCEPT_COOKIES:
      return {
        ...state,
        accepted: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
