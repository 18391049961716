import { TemplateActionTypes, TemplateState, SET_THEME } from './types';
import { Theme } from '../interfaces/Theme';

export const initialState: TemplateState = {
  theme: Theme.Dark,
};

function reducer(state = initialState, action: TemplateActionTypes): TemplateState {
  switch (action.type) {
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
