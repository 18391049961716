import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Automatically scroll to the top of the page on each route change
 */
function RouterScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default RouterScrollToTop;
