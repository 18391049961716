import { LanguagesActionTypes, LanguagesState, SET_CURRENT_LANGUAGE, SET_LANGUAGES } from './types';
import { Languages } from '../interfaces/Languages';

export const initialState: LanguagesState = {
  languages: [Languages.English],
  currentLanguage: Languages.English,
};

function reducer(state = initialState, action: LanguagesActionTypes): LanguagesState {
  switch (action.type) {
    case SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };
    default:
      return state;
  }
}

export default reducer;
