import { lazy } from 'react';
import { BOOKING_EXPIRED, BOOKING_FULL, BOOKING_MANAGE, BOOKING_OPEN_SOON, BOOKING_REGISTRATION } from './uri';

const Registration = lazy(() => import('../pages/RegistrationPage'));
const FullyBooked = lazy(() => import('../pages/FullyBookedPage'));
const Expired = lazy(() => import('../pages/ExpiredPage'));
const OpenSoon = lazy(() => import('../pages/OpenSoon'));
const ManageBooking = lazy(() => import('../pages/ManageBookingPage'));

const routes = [
  // Direct access
  {
    path: `${BOOKING_REGISTRATION}/:target`,
    element: <Registration />,
  },
  {
    path: `${BOOKING_MANAGE}/:target/:guest`,
    element: <ManageBooking />,
  },
  // Internal pages
  {
    path: BOOKING_FULL,
    element: <FullyBooked />,
  },
  {
    path: BOOKING_EXPIRED,
    element: <Expired />,
  },
  {
    path: BOOKING_OPEN_SOON,
    element: <OpenSoon />,
  },
];

export default routes;
