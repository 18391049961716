import { ErrorState, CLEAR_ALL_ERRORS, REMOVE_ERROR, REMOVE_ERRORS } from './types';
import ReduxAction from '../ReduxAction';

const initialState: ErrorState = {};

function reducer(state = initialState, action: ReduxAction<any>): ErrorState {
  const { type, error, payload } = action;

  // Removes an error by it's ID
  if (type === REMOVE_ERROR) {
    // Create a new state without the error that has the same key as the payload
    // Using ES7 Object Rest Spread operator to omit properties from an object
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { [payload]: value, ...newState } = state;

    return newState;
  }

  // Removes multiple errors by their IDs
  if (type === REMOVE_ERRORS) {
    // Create a new state without the errors that have the same keys as the payload
    const newState = { ...state };
    payload.forEach((key: string) => delete newState[key]);

    return newState;
  }

  // Removes all errors by returning the initial state which is an empty object
  if (type === CLEAR_ALL_ERRORS) {
    return initialState;
  }

  // True if the action type has the key word '_FINISHED' then the action is finished
  const isFinishedRequestType = type.includes('_FINISHED');

  // True if the action type has the key word 'REQUEST_' and not '_FINISHED'
  const isStartRequestType = type.includes('REQUEST_') && !isFinishedRequestType;

  // If an action is started we want to remove any old errors because there is a new action has been re-dispatched
  if (isStartRequestType) {
    // Using ES7 Object Rest Spread operator to omit properties from an object
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { [`${type}_FINISHED`]: value, ...stateWithoutFinishedType } = state;

    return stateWithoutFinishedType;
  }

  // True if the action is finished and the error property is true
  const isError: boolean = isFinishedRequestType && Boolean(error);

  // For any start and finished actions that don't have errors we return the current state
  if (isError === false) {
    return state;
  }

  // At this point the "type" will be a finished action type (e.g. "REQUEST_*_FINISHED").
  // The payload will be an object.
  return {
    ...state,
    [type]: payload,
  };
}

export default reducer;
