import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Languages } from '../interfaces/Languages';
import { setCurrentLanguage as actionSetCurrentLanguage } from '../store/actions';
import useGetCurrentLanguage from './useGetCurrentLanguage';

/**
 * Set the current language (both on react-i18next and Redux side)
 */
function useSetCurrentLanguage() {
  const { i18n } = useTranslation('app');

  const dispatch = useDispatch();
  const currentLanguage = useGetCurrentLanguage();

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
  }, [i18n, currentLanguage]);

  const setCurrentLanguage = (key: Languages) => {
    dispatch(actionSetCurrentLanguage(key));
  };

  return { setCurrentLanguage };
}

export default useSetCurrentLanguage;
