import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import LanguageIcon from '@mui/icons-material/Language';
import useGetLanguages from '../../hooks/useGetLanguages';
import useGetCurrentLanguage from '../../hooks/useGetCurrentLanguage';
import useInitializeLanguage from '../../hooks/useInitializeLanguage';
import useSetCurrentLanguage from '../../hooks/useSetCurrentLanguage';
import { getLanguagesWithLabel, Languages } from '../../interfaces/Languages';
import styles from './LanguageSwitcher.module.scss';

function LanguageSwitcher() {
  const { t } = useTranslation('app');

  const currentLanguage = useGetCurrentLanguage();
  const languages = useGetLanguages();
  const { setCurrentLanguage } = useSetCurrentLanguage();

  // Initialize the language (in the Redux store) based on the booking's event configuration
  useInitializeLanguage();

  const [open, setOpen] = useState(false);

  const handleSelectLanguage = (languageCode: Languages) => {
    setCurrentLanguage(languageCode);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        disableElevation
        color='white'
        startIcon={<LanguageIcon />}
        onClick={() => setOpen(true)}
        classes={{
          root: styles.button,
          startIcon: styles.buttonStartIcon,
        }}
      >
        {t('Change language')}
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth='xs'>
        <DialogTitle>{t('Change language')}</DialogTitle>
        <List component='div' sx={{ pt: 0 }}>
          {getLanguagesWithLabel
            .filter((language) => languages.includes(language.code))
            .map((language) => (
              <ListItemButton
                key={language.code}
                divider
                sx={{ px: '32px' }}
                onClick={() => handleSelectLanguage(language.code)}
                selected={currentLanguage === language.code}
              >
                <ListItemText secondary={language.label} />
              </ListItemButton>
            ))}
        </List>
        <DialogActions>
          <Button disableElevation color='primary' variant='outlined' autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LanguageSwitcher;
