import React from 'react';
import { useTranslation } from 'react-i18next';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import scrollToElement from '../utils/scrollToElement';
import { TOP_ANCHOR_ID } from '../Header/Header';

function ScrollTop() {
  const { t } = useTranslation('app');

  const trigger = useScrollTrigger({
    target: window || undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#' + TOP_ANCHOR_ID);
    scrollToElement(anchor);
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        title={t('Scroll back to top')}
      >
        <Fab color='secondary' size='small' aria-label={t('Scroll back to top')}>
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
}

export default ScrollTop;
