import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import LanguageSwitcher from '../../../features/languages/components/LanguageSwitcher/LanguageSwitcher';
import ThemeSwitcher from '../ThemeSwitcher/ThemeSwitcher';
import { COOKIE_POLICY } from '../../../features/cookies/router/uri';
import styles from './Footer.module.scss';

const links = [
  {
    isExternal: true,
    label: 'Privacy Notice',
    href: 'https://www.audemarspiguet.com/com/en/legal/privacy-notice.html',
  },
  {
    isExternal: false,
    label: 'Cookie Policy',
    href: `/${COOKIE_POLICY}`,
  },
];

function Footer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation('app');

  return (
    <Box className={styles.root} sx={{ backgroundColor: theme.palette.secondary.main }}>
      <Container maxWidth='lg'>
        <div className={`${styles.container} ${isMobile ? styles.containerMobile : ''}`}>
          <Stack spacing={2}>
            <LanguageSwitcher />
            <ThemeSwitcher />
          </Stack>
          {links.length && (
            <Box className={styles.links}>
              {links.map((link, index) => {
                if (link.isExternal) {
                  return (
                    <Link
                      key={index}
                      className={styles.link}
                      variant='body2'
                      underline='hover'
                      color='inherit'
                      target='_blank'
                      rel='noopener'
                      href={link.href}
                    >
                      {t(link.label)}
                    </Link>
                  );
                }

                return (
                  <Link
                    key={index}
                    className={styles.link}
                    variant='body2'
                    underline='hover'
                    color='inherit'
                    component={RouterLink}
                    to={link.href}
                  >
                    {t(link.label)}
                  </Link>
                );
              })}
            </Box>
          )}
          <Typography variant='body2' sx={{ fontWeight: 400 }}>
            © {new Date().getFullYear()} Audemars Piguet
          </Typography>
        </div>
      </Container>
    </Box>
  );
}

export default Footer;
