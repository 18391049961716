import { useSelector } from 'react-redux';
import { RootState } from '../../../config/store/rootReducer';

/**
 * Get the current locale language code
 */
function useGetCurrentLanguage() {
  return useSelector((state: RootState) => state.languages.currentLanguage);
}

export default useGetCurrentLanguage;
