import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { init, sendPageView } from '../analytics/analytics';
import { RootState } from '../store/rootReducer';
import useAreCookiesDisabled from '../../features/cookies/utils/useAreCookiesDisabled';

/**
 * Send page view event to Google Analytics
 */
function RouterAnalytics() {
  const location = useLocation();
  const accepted = useSelector((state: RootState) => state.cookies.accepted);

  const cookiesDisabled = useAreCookiesDisabled();

  useEffect(() => {
    if (accepted && !cookiesDisabled) {
      init();
    }
  }, [accepted]);

  useEffect(() => {
    if (accepted) {
      const currentPath = location.pathname + location.search;
      sendPageView(currentPath);
    }
  }, [location, accepted]);

  return null;
}

export default RouterAnalytics;
