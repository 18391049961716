import { CLEAR_ALL_REQUESTING, REMOVE_MULTIPLE_REQUESTING, REMOVE_REQUESTING, RequestingState } from './types';
import ReduxAction from '../ReduxAction';

const initialState: RequestingState = {};

function reducer(state = initialState, action: ReduxAction<any>): RequestingState {
  const { type, payload, meta } = action;

  // Removes a requesting loading state by it's key that is in the action payload
  if (type === REMOVE_REQUESTING) {
    // Create a new state without the requesting loading state that has the same key as the payload
    // Using ES7 Object Rest Spread operator to omit properties from an object
    // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
    const { [payload]: value, ...newState } = state;

    return newState;
  }

  // Removes multiple requesting loading states by their IDs
  if (type === REMOVE_MULTIPLE_REQUESTING) {
    // Create a new state without the requesting loading states that have the same keys as the payload
    const newState = { ...state };
    payload.forEach((key: string) => delete newState[key]);

    return newState;
  }

  // Removes all requesting loading states by returning the initial state which is an empty object
  if (type === CLEAR_ALL_REQUESTING) {
    return initialState;
  }

  // We only take actions that include 'REQUEST_' in the type
  const isRequestType: boolean = type.includes('REQUEST_');

  if (isRequestType === false) {
    return state;
  }

  // Remove the string '_FINISHED' from the action type so we can use the first part as the key on the state
  let requestName: string = type.replace('_FINISHED', '');

  // Check if it is a targeted requesting action, is so append the request ID to the name
  // NB; the request ID can be an integer or a string
  if (
    meta &&
    Object.prototype.hasOwnProperty.call(meta, 'reduxRequestId') &&
    (Number.isInteger(meta.reduxRequestId) ||
      typeof meta.reduxRequestId === 'string' ||
      meta.reduxRequestId instanceof String)
  ) {
    requestName += `_${meta.reduxRequestId}`;
  }

  // If the action type includes '_FINISHED'. The boolean value will be false. Otherwise we
  // assume it is a starting request and will be set to true.
  const isFinishedRequestType: boolean = type.includes('_FINISHED');

  return {
    ...state,
    [requestName]: isFinishedRequestType === false,
  };
}

export default reducer;
