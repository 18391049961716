import { useSelector } from 'react-redux';
import { RootState } from '../../../config/store/rootReducer';

/**
 * Get the languages (that match the booking configuration)
 */
function useGetLanguages() {
  return useSelector((state: RootState) => state.languages.languages);
}

export default useGetLanguages;
