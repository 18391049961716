import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../config/store/rootReducer';

/**
 * Some page should not be accessed from a direct link.
 * RegistrationPage is the only component that should redirect to this page.
 * Therefore, if "booking" is null in the state, it means this page has been accessed directly -> redirect to 404
 */
function usePreventDirectPageAccess() {
  const booking = useSelector((state: RootState) => state.booking.booking);

  const navigate = useNavigate();

  useEffect(() => {
    if (!booking) {
      navigate('/404');
    }
  }, [booking]);
}

export default usePreventDirectPageAccess;
