import { Helmet, HelmetProvider } from 'react-helmet-async';
import Container from '@mui/material/Container';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Router from '../../../config/router/Router';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ScrollTop from '../ScrollTop/ScrollTop';
import CookiesDialog from '../../../features/cookies/components/CookiesDialog/CookiesDialog';
import '../../assets/style/main.scss';
import useIsDarkTheme from '../../hooks/useIsDarkTheme';

const pageTitle = 'Audemars Piguet';

function Layout() {
  const isDarkTheme = useIsDarkTheme();

  return (
    <HelmetProvider>
      <Helmet defaultTitle={pageTitle} titleTemplate={`${pageTitle} | %s`} />
      <Header />
      <Container maxWidth='lg'>
        <main id='site-main' role='main' data-dark-theme={isDarkTheme ? '1' : '0'}>
          <div className='container'>
            <Router />
          </div>
        </main>
      </Container>
      <Footer />
      <ScrollTop />
      <CookiesDialog />
      <ToastContainer position='bottom-right' autoClose={5000} hideProgressBar closeOnClick pauseOnHover draggable />
    </HelmetProvider>
  );
}

export default Layout;
