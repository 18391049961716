import { JSX, useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { createRoutesFromChildren, matchRoutes } from 'react-router';
import * as Sentry from '@sentry/react';
import type { BrowserOptions } from '@sentry/browser';

export const sentryOptions: BrowserOptions = {
  debug: false,
  enabled: 'true'.localeCompare(import.meta.env.VITE_SENTRY_ENABLED) === 0,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  release: `ap-events-frontend@${import.meta.env.VITE_VERSION}`,
  /**
   * It controls for which URLs distributed tracing should be enabled
   * TODO: add import.meta.env.VITE_API_URL when NestJS will be ready
   *  <!> if the env variable is passed but empty, the site will crash because the Sentry headers will be passed to
   *  the IDP and rejected by the CORS policy
   */
  tracePropagationTargets: ['localhost', import.meta.env.VITE_BACKEND_API_URL],
  integrations: [
    new Sentry.BrowserTracing({
      // React Router integration to create navigation transactions in Sentry
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
  ],
  normalizeDepth: 5,
  tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE),
  replaysSessionSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE),
  replaysOnErrorSampleRate: Number(import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE),
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'console' && breadcrumb.message && breadcrumb.message.startsWith('%c')) {
      // Exclude AppConsole console output
      return null;
    }
    return breadcrumb;
  },
};

interface Props {
  children: JSX.Element;
}

function AppSentryProvider(props: Props) {
  return <Sentry.ErrorBoundary>{props.children}</Sentry.ErrorBoundary>;
}

export default Sentry.withProfiler(AppSentryProvider);
