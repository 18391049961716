import { scrollIntoView } from 'seamless-scroll-polyfill';

/**
 * Scrolls to the start of the given element
 * Uses scrollIntoView from a polyfill library for compatibility issues with Safari
 *
 * @param element, the document element
 */
function scrollToElement(element: Element | null) {
  if (element) {
    scrollIntoView(element, {
      behavior: 'smooth',
      block: 'start',
    });
  }
}

export default scrollToElement;
