import { lazy } from 'react';

const GenericServerError = lazy(() => import('../pages/GenericServerError'));
const NotFound = lazy(() => import('../pages/NotFound'));

const routes = [
  {
    path: '5:code', // 5xx
    element: <GenericServerError />,
  },
  {
    path: '*', // 404
    element: <NotFound />,
  },
];

export default routes;
