export const REMOVE_REQUESTING = 'REMOVE_REQUESTING';
export const REMOVE_MULTIPLE_REQUESTING = 'REMOVE_MULTIPLE_REQUESTING';
export const CLEAR_ALL_REQUESTING = 'CLEAR_ALL_REQUESTING';

interface RemoveRequestingAction {
  type: typeof REMOVE_REQUESTING;
  payload: string;
}

interface RemoveMultipleRequestingAction {
  type: typeof REMOVE_MULTIPLE_REQUESTING;
  payload: Array<string>;
}

interface ClearAllRequestingAction {
  type: typeof CLEAR_ALL_REQUESTING;
}

export interface RequestingState {
  readonly [key: string]: boolean;
}

export interface MetaCustomRequesting {
  reduxRequestId: string | number;
}

export type RequestingActionTypes = RemoveRequestingAction | RemoveMultipleRequestingAction | ClearAllRequestingAction;
