import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from './rootReducer';

type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;

const useAppDispatch = () => useDispatch<TypedDispatch<RootState>>();

export default useAppDispatch;
