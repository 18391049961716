import React from 'react';
import { createTheme } from '@mui/material/styles';
import colors from './assets/style/colors.module.scss';

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
    white: Palette['primary'];
  }
  interface PaletteOptions {
    black?: PaletteOptions['primary'];
    white?: PaletteOptions['primary'];
  }
  interface TypographyVariants {
    h1sub: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    h1sub?: React.CSSProperties;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    black: true;
    white: true;
  }

  interface ButtonPropsVariantOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    black: true;
    white: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1sub: true;
    h2sub: true;
  }
}

const breakpoints: { [key in 'xs' | 'sm' | 'md' | 'lg' | 'xl']: number } = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

const fontFamily = 'HelveticaNeueLTCom, Roboto, "Helvetica Neue", Arial, sans-serif';

function getTheme(darkMode: boolean) {
  let theme = createTheme({
    breakpoints: {
      values: breakpoints,
    },
    spacing: [0, 4, 8, 16, 32, 64],
    palette: {
      mode: darkMode ? 'dark' : 'light',
      ...(darkMode
        ? {
            primary: {
              main: colors.colorWhite,
              contrastText: colors.colorPrimary,
            },
            secondary: {
              main: colors.colorSecondary,
            },
            black: {
              main: colors.colorBlack,
              contrastText: colors.colorWhite,
            },
            white: {
              main: colors.colorWhite,
              contrastText: colors.colorBlack,
            },
            grey: {
              '200': colors.colorGreyAp,
              '500': colors.colorLabel,
            },
            background: {
              default: colors.colorBlack,
            },
          }
        : {
            primary: {
              main: colors.colorPrimary,
              contrastText: colors.colorWhite,
            },
            secondary: {
              main: colors.colorSecondary,
            },
            black: {
              main: colors.colorBlack,
              contrastText: colors.colorWhite,
            },
            white: {
              main: colors.colorWhite,
              contrastText: colors.colorBlack,
            },
            grey: {
              '200': colors.colorGreyAp,
              '500': colors.colorLabel,
            },
          }),
    },
    typography: {
      h2: {
        fontFamily,
      },
      h3: {
        fontFamily,
      },
      h4: {
        fontFamily,
      },
      h5: {
        fontFamily,
      },
      h6: {
        fontFamily,
      },
      body1: {
        fontFamily,
        fontSize: '17px',
        lineHeight: '1.35',
        letterSpacing: '0.26px',
      },
      body2: {
        fontFamily,
        fontSize: '14px',
        fontWeight: 600,
      },
      caption: {
        fontFamily,
        fontSize: '14px',
        fontWeight: 300,
        letterSpacing: '0.015rem',
      },
    },
  });

  // Theme composition: using theme options to define other options
  theme = createTheme(theme, {
    typography: {
      h1: {
        fontFamily: 'HelveticaNeueLTCom-UltLt, Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: '36px',
        fontWeight: 400,
        letterSpacing: '-0.36px',
        lineHeight: '36px',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
          fontSize: '56px',
          letterSpacing: '-0.56px',
          lineHeight: '56px',
        },
      },
      h1sub: {
        display: 'block',
        fontFamily: 'TimesNow-ExtraLightItalic, Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: '39px',
        fontWeight: 400,
        letterSpacing: '-0.78px',
        lineHeight: '0.83',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
          fontSize: '60px',
          letterSpacing: '-1.2px',
          lineHeight: '50px',
        },
      },
      h2: {
        fontSize: '30px',
        lineHeight: '30px',
        letterSpacing: '4px',
        textTransform: 'uppercase',
      },
      h2sub: {
        fontSize: '12px',
        fontWeight: 600,
        letterSpacing: '2.4px',
        textTransform: 'uppercase',
      },
      h6: {
        fontWeight: 600,
        fontSize: 16,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          gutterBottom: {
            marginBottom: theme.spacing(4),
          },
        },
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            paddingLeft: '20px',
            paddingRight: '20px',
            [theme.breakpoints.up('sm')]: {
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            borderRadius: 0,
            fontFamily,
            fontWeight: 600,
          },
          tertiary: {
            fontFamily,
            fontSize: 14,
          },
          sizeLarge: {
            fontSize: '16px',
            minHeight: '64px',
            minWidth: '100%',
            [theme.breakpoints.up('sm')]: {
              minWidth: '200px',
            },
          },
          startIcon: {
            marginTop: '-2px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: darkMode ? theme.palette.grey['900'] : theme.palette.grey['200'],
            border: 'none',
            boxShadow: 'none',
            borderRadius: '0',
            '&.MuiDialog-paper': {
              backgroundColor: theme.palette.background.default,
              boxShadow: 2,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${theme.palette.background.default}`,
            '&.MuiTableCell-head': {
              fontWeight: 600,
            },
            '&.MuiTableCell-body': {
              fontWeight: 400,
            },
          },
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: theme.spacing(3),
            paddingTop: theme.spacing(1),
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 0,
          },
        },
        defaultProps: {
          slotProps: {
            backdrop: {
              style: {
                backgroundColor: darkMode ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.65)',
              },
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(3)}`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: `${theme.spacing(3)} ${theme.spacing(4)} ${theme.spacing(4)}`,
            // paddingLeft: `calc(${theme.spacing(4)} - ${theme.spacing(2)})`,
            // paddingRight: `calc(${theme.spacing(4)} - ${theme.spacing(2)})`,
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            minHeight: '90px !important',
            [theme.breakpoints.up('sm')]: {
              minHeight: '100px !important',
            },
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: `1px solid ${theme.palette.grey['500']}`,
            borderRadius: 0,
            color: darkMode ? `${theme.palette.primary.main}` : `${theme.palette.grey['500']}`,
            backgroundColor: theme.palette.background.default,
            ...(!darkMode
              ? {
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.primary.main}`,
                    color: `${theme.palette.white.main}`,
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                  '&.Mui-selected:hover ': {
                    backgroundColor: `${theme.palette.primary.main}`,
                    color: `${theme.palette.white.main}`,
                  },
                  '&:hover': {
                    color: `${theme.palette.primary.main}`,
                    border: `1px solid ${theme.palette.primary.main}`,
                  },
                }
              : {}),
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            fontSize: 15,
            lineHeight: '15px',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: 'transparent',
            padding: '3px',
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '0',
            '&.Mui-checked > .MuiSvgIcon-root': {
              color: theme.palette.primary.main,
            },
          },
        },
        defaultProps: {
          size: 'small',
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            margin: '15px 0 15px 0',
            '&:before': {
              borderBottom: `1px solid ${theme.palette.primary.main}`,
            },
            '&.Mui-error:before': {
              borderBottomColor: theme.palette.primary.main,
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily,
            fontWeight: 600,
            textTransform: 'none',
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 700,
            fontFamily,
            fontSize: '10px',
            color: darkMode ? theme.palette.primary.main : theme.palette.grey['500'],
            letterSpacing: '2px',
            textTransform: 'uppercase',
            marginBottom: '-10px', // cancel margin top of input + 5px
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            label: {
              fontWeight: 700,
              fontFamily,
              fontSize: '10px',
              letterSpacing: '2px',
              textTransform: 'uppercase',
              '&.MuiInputLabel-shrink': {
                transform: 'none',
                transition: 'none',
              },
              color: darkMode ? theme.palette.primary.main : theme.palette.grey['500'],
            },
          },
        },
      },
      MuiCalendarPicker: {
        styleOverrides: {
          root: {
            div: {
              fontSize: '14px',
              fontWeight: 600,
            },
            '.MuiTypography-root': {
              color: `${theme.palette.primary.main}`,
              fontWeight: 600,
            },
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            padding: '4px',
            width: '40px',
            height: '40px',
          },
          today: {
            borderRadius: 0,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:not(.Mui-disabled) .MuiSvgIcon-root': {
              fill: `${theme.palette.primary.main}`,
              '&:hover': {
                background: 'transparent',
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            marginLeft: 0,
          },
        },
      },
    },
  });

  return theme;
}

export default getTheme;
