import { Theme } from '../interfaces/Theme';

export const SET_THEME = 'SET_THEME';

interface SetThemeAction {
  type: typeof SET_THEME;
  payload: Theme;
}

/**
 * Redux State
 */

export interface TemplateState {
  theme: Theme;
}

/**
 * Redux Actions Types
 */

export type TemplateActionTypes = SetThemeAction;
