import Box from '@mui/material/Box';
import usePreventDirectPageAccess from '../../booking/router/usePreventDirectPageAccess';
import AlreadyRsvpHero from '../../booking/components/AlreadyRsvpHero/AlreadyRsvpHero';

function AlreadyRsvpPage() {
  usePreventDirectPageAccess();

  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <AlreadyRsvpHero />
    </Box>
  );
}

export default AlreadyRsvpPage;
