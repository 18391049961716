import { CookiesActionTypes, SET_ACCEPT_COOKIES } from './types';

export const ACCEPT_COOKIES_NAME = 'ap_cookies';

export const setAcceptCookies = (accepted: boolean): CookiesActionTypes => {
  if (accepted) {
    const date = new Date();
    date.setMonth(date.getMonth() + 12);
    document.cookie = `${ACCEPT_COOKIES_NAME}=true; path=/; expires=${date.toUTCString()}`;
  }

  return {
    type: SET_ACCEPT_COOKIES,
    payload: accepted,
  };
};
