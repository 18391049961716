import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import useAppDispatch from '../../../config/store/useAppDispatch';
import useIsDarkTheme from '../../hooks/useIsDarkTheme';
import { setTheme } from '../../store/actions';
import { Theme } from '../../interfaces/Theme';
import styles from './ThemeSwitcher.module.scss';

function ThemeSwitcher() {
  const { t } = useTranslation('app');

  const dispatch = useAppDispatch();
  const isDarkTheme = useIsDarkTheme();

  const [open, setOpen] = useState(false);

  const handleSelect = (theme: Theme) => {
    dispatch(setTheme(theme));
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        disableElevation
        color='white'
        startIcon={<Brightness4Icon />}
        onClick={() => setOpen(true)}
        classes={{
          root: styles.button,
          startIcon: styles.buttonStartIcon,
        }}
      >
        {t('Change appearance')}
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth='xs'>
        <DialogTitle>{t('Change appearance')}</DialogTitle>
        <List component='div' sx={{ pt: 0 }}>
          <ListItemButton divider sx={{ px: '32px' }} onClick={() => handleSelect(Theme.Light)} selected={!isDarkTheme}>
            <ListItemText secondary={t('Light')} />
          </ListItemButton>
          <ListItemButton divider sx={{ px: '32px' }} onClick={() => handleSelect(Theme.Dark)} selected={isDarkTheme}>
            <ListItemText secondary={t('Dark')} />
          </ListItemButton>
        </List>
        <DialogActions>
          <Button disableElevation color='primary' variant='outlined' autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ThemeSwitcher;
