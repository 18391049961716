import { lazy } from 'react';
import { ALREADY_REGISTERED, RSVP } from './uri';
import AlreadyRsvpPage from '../pages/AlreadyRsvpPage';

const Rsvp = lazy(() => import('../pages/RsvpPage'));

const routes = [
  {
    path: `${RSVP}/:target`,
    element: <Rsvp />,
  },
  {
    path: `${RSVP}/:target/:guest`,
    element: <Rsvp />,
  },
  {
    path: `${ALREADY_REGISTERED}`,
    element: <AlreadyRsvpPage />,
  },
];

export default routes;
