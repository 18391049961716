import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { RootState } from '../../../../config/store/rootReducer';
import { setAcceptCookies } from '../../store/actions';
import { COOKIE_POLICY } from '../../router/uri';
import styles from './CookiesDialog.module.scss';
import useAreCookiesDisabled from '../../utils/useAreCookiesDisabled';

function CookiesDialog() {
  const { t } = useTranslation('cookies');

  const location = useLocation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const accepted = useSelector((state: RootState) => state.cookies.accepted);

  const cookiesDisabled = useAreCookiesDisabled();

  const handleClose = () => {
    dispatch(setAcceptCookies(true));
  };

  if (location.pathname === `/${COOKIE_POLICY}`) {
    // Disable the dialog when the user browse the Cookie Policy page
    return null;
  }

  return (
    <Fragment>
      {!cookiesDisabled && (
        <Dialog
          open={!accepted}
          onClose={() => {}}
          fullWidth={true}
          maxWidth='lg'
          disableEscapeKeyDown={true}
          classes={{ paper: styles.dialogPaper, scrollPaper: styles.dialogScrollPaper }}
        >
          <Box className={styles.container} sx={{ ...(isMobile && { p: '32px !important' }) }}>
            <Stack direction={isMobile ? 'column' : 'row'} justifyContent='space-between' spacing={3}>
              <Typography variant='body2' mr={isMobile ? 0 : 5}>
                {t(
                  'We use Cookies to improve your user experience, monitor analytics and optimize advertising campaigns when you use our Services.',
                )}
              </Typography>
              <Button
                disableElevation
                color='primary'
                variant='outlined'
                component={RouterLink}
                to={`/${COOKIE_POLICY}`}
                sx={{ ...(!isMobile && { minWidth: '180px', textAlign: 'center' }) }}
              >
                {t('Read our cookie policy')}
              </Button>
              <Button
                disableElevation
                color='primary'
                variant='contained'
                onClick={handleClose}
                sx={{ ...(!isMobile && { minWidth: '180px' }) }}
                size='large'
              >
                {t('Accept all')}
              </Button>
            </Stack>
          </Box>
        </Dialog>
      )}
    </Fragment>
  );
}

export default CookiesDialog;
