import { useEffect } from 'react';

const printConsole = () => {
  const version = import.meta.env.VITE_VERSION ? import.meta.env.VITE_VERSION.toString() : 'Unknown version';

  const message1 = `
       d8888 8888888b.       888888b.                     888      d8b                   
      d88888 888   Y88b      888  "88b                    888      Y8P                   
     d88P888 888    888      888  .88P                    888                            
    d88P 888 888   d88P      8888888K.   .d88b.   .d88b.  888  888 888 88888b.   .d88b.  
   d88P  888 8888888P"       888  "Y88b d88""88b d88""88b 888 .88P 888 888 "88b d88P"88b 
  d88P   888 888             888    888 888  888 888  888 888888K  888 888  888 888  888 
 d8888888888 888             888   d88P Y88..88P Y88..88P 888 "88b 888 888  888 Y88b 888 
d88P     888 888             8888888P"   "Y88P"   "Y88P"  888  888 888 888  888  "Y88888 
                                                                                     888 
                                                                                Y8b d88P 
                                                                                 "Y88P\n`;
  const message2 = `Version ${version}`;
  const message3 = 'Made with love in 🇨🇭 Le Brassus by Audemars Piguet\n';

  console.log(`%c ${message1}`, 'font-size: 7px; font-weight: bold;');
  console.log(`%c${message2}`, 'font-weight: bold;');
  console.log(`%c${message3}`, 'font-weight: normal;');
};

function AppConsole() {
  useEffect(() => {
    console.clear();
    printConsole();
  }, []);

  return null;
}

export default AppConsole;
