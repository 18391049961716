import ReactGA from 'react-ga4';
import { UaEventOptions } from 'react-ga4/types/ga4';

const MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID || '';
const TRACKING_DISABLED = import.meta.env.VITE_GA_TRACKING_DISABLED === 'true';

function init() {
  if (TRACKING_DISABLED || MEASUREMENT_ID === '') {
    return false;
  }

  ReactGA.initialize(MEASUREMENT_ID);
}

function sendEvent(options: UaEventOptions) {
  ReactGA.event(options);
}

function sendPageView(page: string) {
  ReactGA.send({ hitType: 'pageview', page });
}

export { init, sendEvent, sendPageView };
