export const SET_ACCEPT_COOKIES = 'SET_ACCEPT_COOKIES';

interface SetAcceptCookiesAction {
  type: typeof SET_ACCEPT_COOKIES;
  payload: boolean;
}

/**
 * Redux State
 */

export interface CookiesState {
  accepted: boolean;
}

/**
 * Redux Actions Types
 */

export type CookiesActionTypes = SetAcceptCookiesAction;
