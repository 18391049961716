import { TOptions } from 'i18next';
import { useTranslation, Namespace } from 'react-i18next';

export enum I18nContext {
  REGISTRATION_ADDITIONAL_GUESTS_FORM = 'RegistrationAdditionalGuestsForm',
  REGISTRATION_FORM = 'RegistrationForm',
  REGISTRATION_TIME_SLOTS = 'RegistrationTimeSlots',
  MANAGE_BOOKING_PAGE = 'ManageBookingPage',
  SUMMARY = 'SummaryPage',
  NAVIGATION = 'Navigation',
  STATIC_PAGE = 'StaticPage',
}

export const useContextTranslation = (namespace: Namespace) => {
  const { t } = useTranslation(namespace);

  return {
    tRegistrationAdditionalGuestsForm: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.REGISTRATION_ADDITIONAL_GUESTS_FORM });
    },
    tRegistrationForm: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.REGISTRATION_FORM });
    },
    tRegistrationTimeSlots: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.REGISTRATION_TIME_SLOTS });
    },
    tManageBookingPage: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.MANAGE_BOOKING_PAGE });
    },
    tSummary: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.SUMMARY });
    },
    tNavigation: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.NAVIGATION });
    },
    tStaticPage: (key: string | string[], args?: TOptions) => {
      return t(key, { ...args, context: I18nContext.STATIC_PAGE });
    },
  };
};
