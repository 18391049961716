import { Helmet } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import { useContextTranslation } from '../../../languages/hooks/useI18nContext';

function AlreadyRsvpHero() {
  const { tStaticPage: t } = useContextTranslation('booking');

  return (
    <div>
      <Helmet>
        <title>
          {t('You have already')} {t('been registered')}
        </title>
      </Helmet>
      <Typography variant='h1' gutterBottom>
        {t('You have already')}
        <Typography variant='h1sub'>{t('been registered')}</Typography>
      </Typography>
      <Typography gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
        {t('We look forward to seeing you.')}
      </Typography>
      <Typography>{t('Your AP team')}</Typography>
    </div>
  );
}

export default AlreadyRsvpHero;
