import { RsvpActionTypes, RsvpState, REQUEST_RSVP_FINISHED } from './types';

export const initialState: RsvpState = {
  rsvp: null,
};

function reducer(state = initialState, action: RsvpActionTypes): RsvpState {
  switch (action.type) {
    case REQUEST_RSVP_FINISHED:
      return {
        ...state,
        rsvp: action.payload && !action.error ? action.payload : initialState.rsvp,
      };
    default:
      return state;
  }
}

export default reducer;
