import { useSelector } from 'react-redux';
import { RootState } from '../../config/store/rootReducer';
import { Theme } from '../interfaces/Theme';

function useIsDarkTheme() {
  const theme = useSelector((state: RootState) => state.template.theme);

  return theme === Theme.Dark;
}

export default useIsDarkTheme;
