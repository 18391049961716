export const REMOVE_ERROR = 'REMOVE_ERROR';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const CLEAR_ALL_ERRORS = 'CLEAR_ALL_ERRORS';

interface RemoveErrorAction {
  type: typeof REMOVE_ERROR;
  payload: string;
}

interface RemoveErrorsAction {
  type: typeof REMOVE_ERRORS;
  payload: Array<string>;
}

interface ClearAllErrorsAction {
  type: typeof CLEAR_ALL_ERRORS;
}

export interface ErrorState {
  readonly [key: string]: object;
}

export type ErrorActionTypes = RemoveErrorAction | RemoveErrorsAction | ClearAllErrorsAction;
