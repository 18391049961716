import Rsvp from '../interfaces/Rsvp';

/**
 * Get a booking
 */

export const REQUEST_RSVP = 'REQUEST_RSVP';
export const REQUEST_RSVP_FINISHED = 'REQUEST_RSVP_FINISHED';

interface RequestRsvpAction {
  type: typeof REQUEST_RSVP;
}

interface RequestRsvpFinishedAction {
  type: typeof REQUEST_RSVP_FINISHED;
  payload?: Rsvp;
  error?: any;
}

/**
 * Redux State
 */

export interface RsvpState {
  rsvp: Rsvp | null;
}

/**
 * Redux Actions Types
 */

export type RsvpActionTypes = RequestRsvpAction | RequestRsvpFinishedAction;
