import { useEffect, useMemo, Fragment } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import getTheme from '../template/theme';
import { Theme } from '../template/interfaces/Theme';
import useAppDispatch from '../config/store/useAppDispatch';
import { setTheme } from '../template/store/actions';
import useIsDarkTheme from '../template/hooks/useIsDarkTheme';
import AppConsole from './AppConsole';

interface Props {
  children: JSX.Element;
}

function AppThemeProvider(props: Props) {
  const { children } = props;

  const dispatch = useAppDispatch();
  const isDarkTheme = useIsDarkTheme();
  const prefersDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

  const muiTheme = useMemo(() => {
    return getTheme(isDarkTheme);
  }, [isDarkTheme]);

  useEffect(() => {
    dispatch(setTheme(prefersDarkTheme ? Theme.Dark : Theme.Light));
  }, [prefersDarkTheme]);

  return (
    <Fragment>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
      <AppConsole />
    </Fragment>
  );
}

export default AppThemeProvider;
