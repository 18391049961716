import { lazy } from 'react';

const Maintenance = lazy(() => import('../pages/Maintenance'));

const routes = [
  {
    path: '*',
    element: <Maintenance />,
  },
];

export default routes;
