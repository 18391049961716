import { Languages } from '../interfaces/Languages';

export const SET_CURRENT_LANGUAGE = 'SET_LANGUAGE';

interface SetCurrentLanguageAction {
  type: typeof SET_CURRENT_LANGUAGE;
  payload: Languages;
}

export const SET_LANGUAGES = 'SET_LANGUAGES';

interface SetLanguagesAction {
  type: typeof SET_LANGUAGES;
  payload: Languages[];
}

/**
 * Redux State
 */

export interface LanguagesState {
  languages: Languages[];
  currentLanguage: Languages;
}

/**
 * Redux Actions Types
 */

export type LanguagesActionTypes = SetCurrentLanguageAction | SetLanguagesAction;
