import { LanguagesActionTypes, SET_CURRENT_LANGUAGE, SET_LANGUAGES } from './types';
import { Languages } from '../interfaces/Languages';

export const setCurrentLanguage = (language: Languages): LanguagesActionTypes => {
  return {
    type: SET_CURRENT_LANGUAGE,
    payload: language,
  };
};

export const setLanguages = (languages: Languages[]): LanguagesActionTypes => {
  return {
    type: SET_LANGUAGES,
    payload: languages,
  };
};
