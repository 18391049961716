import { Languages } from '../../languages/interfaces/Languages';

function getFullNameLocalized(firstName: string, lastName: string, language: Languages) {
  if (language === Languages.Japanese) {
    return `${lastName} ${firstName}`;
  }
  return `${firstName} ${lastName}`;
}

export default getFullNameLocalized;
