import { lazy } from 'react';
import { COOKIE_POLICY } from './uri';

const Cookie = lazy(() => import('../pages/CookiePolicy'));

const routes = [
  {
    path: COOKIE_POLICY,
    element: <Cookie />,
  },
];

export default routes;
