import { Fragment, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import Layout from '../template/components/Layout/Layout';
import AppThemeProvider from './AppThemeProvider';
import configureStore from '../config/store/configureStore';
import RouterScrollToTop from '../config/router/RouterScrollToTop';
import RouterAnalytics from '../config/router/RouterAnalytics';
import { msalConfig } from '../config/auth/msal';
import '../config/i18n/config';
import AppSentryProvider from './AppSentryProvider';

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * It's initialized outside of the component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

const store = configureStore();

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <AppThemeProvider>
          <AppSentryProvider>
            <Suspense fallback={<Fragment />}>
              <BrowserRouter>
                <RouterScrollToTop />
                <RouterAnalytics />
                <Layout />
              </BrowserRouter>
            </Suspense>
          </AppSentryProvider>
        </AppThemeProvider>
      </Provider>
    </MsalProvider>
  );
}

export default App;
